<template>
  <div>
    <eden-page-header
      :title="'Cleaner Directory'"
      :subtitle="'Add new cleaner'"
    />
    <cleaner-form :action="'add'" />
  </div>
</template>

<script>
import CleanerForm from "@/components/Logistics/Directory/Cleaners/CleanerForm.vue";
import * as actions from "@/store/action-types";

export default {
  name: "CleanerAdd",
  components: { CleanerForm },
  created() {
    this.$store.dispatch(actions.GET_LOCATION_AREAS_LIST);
  },
};
</script>
